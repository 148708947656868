import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const About = ({ contentModuleId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLayoutAbout {
        edges {
          node {
            id
            headingLeft
            descriptionLeft {
              descriptionLeft
            }
            headingRight
            missions
          }
        }
      }
    }
  `);

  const content = data.allContentfulLayoutAbout.edges.find(
    edge => edge.node.id === contentModuleId
  );

  return (
    <section id="about" className="about-us">
      <div className="container section mx-auto">
        <div className="about-us__content">
          <h2
            className="section__title "
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            {content.node.headingLeft}
          </h2>
          <p
            data-sal="slide-up"
            data-sal-easing="ease-in-cubic"
            data-sal-delay="100"
          >
            {content.node.descriptionLeft.descriptionLeft}
          </p>
        </div>
        <div className="about-us__content">
          <h2
            className="section__title"
            data-sal="fade"
            data-sal-easing="ease-in-cubic"
          >
            {content.node.headingRight}
          </h2>
          <p
            data-sal="slide-up"
            data-sal-easing="ease-in-cubic"
            data-sal-delay="100"
          >
            <ul className="ml-12 mt-5" style={{ listStyleType: "disc" }}>
              {content.node.missions.map(mission => (
                <li key={mission}>{mission}</li>
              ))}
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};

About.propTypes = {
  contentModuleId: PropTypes.string.isRequired,
};

export default About;
